import React, { Component } from "react";
import { FiCast, FiDollarSign, FiUsers, FiMonitor } from "react-icons/fi";
import {
    Row, Col, Card, CardBody, Progress, UncontrolledTooltip, Button, Alert, FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    Spinner,
    UncontrolledAlert,
} from 'reactstrap';
const ServiceList = [
    {
        //icon: <FiCast />,
        icon: <img class="service-logo" src={`/assets/images/icon_infinite.png`} alt="Infinite" />,
        title: 'Unlimited uploads. Unlimited streams.',
        title2: 'Free.',
        description: 'Artists have all the power with unlimited uploading capabilities. All users are endowed with unlimited streaming capabilities. UGS is free for everyone.'
    },
    {
        //icon: <FiDollarSign />,
        icon: <img class="service-logo" src={`/assets/images/icon_handshake.png`} alt="Handshake" />,
        title: 'Connect and grow.',
        description: 'Users are able to connect with each other and build their network seamlessly.'
    },
    {
        //icon: <FiUsers />,
        icon: <img class="service-logo" src={`/assets/images/icon_dollar.png`} alt="Dolla" />,
        title: 'Direct user-to-user support.',
        description: 'Users directly support each other via our in-app currency; UGS Gems. All users can purchase, receive, send and withdraw UGS Gems.'
    }
]


class ServiceThree extends Component {
    render() {
        const { column } = this.props;
        const ServiceContent = ServiceList.slice(0, this.props.item);

        return (
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map((val, i) => (
                        <div className={`${column}`} key={i}>
                            <div className="service service__style--2 text-center align-items-center justify-content-center">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div >
                                    <p className="item-title-medium">{val.title}</p>
                                    {val.title2 && <p className="item-title-medium move-top">{val.title2}</p>}
                                    <p className="item-description-medium">{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
