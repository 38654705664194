import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactOne from "../elements/contact/ContactOne";
import ServiceList from "../elements/service/ServiceList";
import WorksList from "../elements/works/WorksList";
import {
    Row, Col, Card, CardBody, Progress, UncontrolledTooltip, Button, Alert, FormGroup,
} from 'reactstrap';

const SlideList = [
    {
        textPosition: 'text-left',
        description: '',
        buttonText: 'Download App',
        buttonLink: process.env.REACT_APP_APP_STORE_URL
    }
]
const PortfolioLanding = () => {
    return (
        <div className="active-dark bg_color--ugs">
            <Helmet pageTitle="Home" />

            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />

            {/* Start Slider Area   */}
            <br /><br />
            <div className="top-inset-main">
                <div id="home" className="fix bg_image bg_image--ugs-mockup">
                    <div className="slider-wrapper">
                        {/* Start Single Slide */}
                        {SlideList.map((value, index) => (
                            <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12" >
                                            <div className={`inner ${value.textPosition}`}>
                                                <img className="logo" src={`/assets/images/ugs-logo.png`} alt="UGS Logo" />

                                                <p className="header-title">UGS - Underground Sound<br />

                                                </p>
                                                <p className="header-sub-title-light">For artists, by artists.</p>
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn mt--30"><a className="btn-default" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* End Single Slide */}
                    </div>
                </div>
            </div>
            {/* End Slider Area   */}

            {/* Start Our Mission Area */}
            <Card id="mission" className="fix top-inset bg_color--ugs">
                <CardBody>
                    <div className="service-area creative-service-wrapper pb--120" >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30 mb_sm--0">
                                        <br />
                                        <p className="section-title">Our Mission</p>
                                        <p className="section-description">The music industry & streaming services are antiquated. UGS is here to change that. With a bold mission to revolutionize the way music is distributed, consumed and monetized, Underground Sound is a platform made for artists, by artists. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <ServiceList item="3" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>

                        </div>
                    </div>

                </CardBody>
            </Card>



            {/* End Our Mission Area */}

            {/* Start How Our App Works Area  */}
            <div id="howitworks" className="fix top-inset-main">
                <div className="service-area creative-service-wrapper pb--120 card_color--ugs" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30 mb_sm--0">
                                    <br /><br />
                                    <p className="section-title">How UGS works</p>
                                    <p className="section-description">Our app is designed for artists and music lovers alike. There are two types of users on UGS; <b>Musicians & Listeners</b>. These user types are codependent. Musicians upload and distribute music directly on the UGS platform. Comments are basic, unhelpful, and generally encourage irrelevant negativity. We want to promote artistic growth, so rather than a generic comment feature, we have created our own <b>review & rating</b> system.</p>
                                    <p className="section-description">With UGS, artists receive feedback from their fans and fellow users in order to help them improve. Furthermore, fans can now discover and connect with new artists easier than ever. By integrating and revolutionizing features of social media and music streaming, UGS creates a community like no other.</p>
                                    <p className="section-description">On our platform, users are able to directly support each other using our proprietary virtual gifting system called “UGS Gems”. Gems are donated directly on posts and on reviews. Additionally, each user’s profile contains a support button for anyone to donate to! UGS Gems can be withdrawn for real cash via PayPal, CashApp, or Venmo.</p>
                                </div>
                                <br/>
                                <img src={`/assets/images/ugs-mockup2.png`} alt="How our app works" />
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <WorksList item="4" column="col-lg-6 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End How Our App Works Area  */}


            {/* Start Contact Area */}
            <Card id="contact" className="ugs-card bottom-inset align-items-center justify-content-center">
                <CardBody>
                    <div id="contact" className="fix">
                        <div className="rn-contact-area pb--120 card_color--ugs">
                            <ContactOne contactImages="/assets/images/about/about-13.jpg" contactTitle="Hire Me." />
                        </div>
                    </div>
                </CardBody>
            </Card>
            {/* End COntact Area */}

            {/* Start Slider Area   */}
            <div id="downloads" className="fix bg_image bg_image--ugs-mockup3">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12" >
                                        <div className={`inner ${value.textPosition}`}>
                                            <div className="container">
                                                <div className="row">
                                                    <p className="footer-title">Download&nbsp;</p>
                                                    <p className="footer-title-large">Underground Sound</p>
                                                    <p className="footer-title">&nbsp;App</p>
                                                </div>
                                            </div>

                                            <p className="footer-sub-title">Available on the App Store.</p>

                                            <div className="slide-btn mt--30"><a className="btn-default" href={process.env.REACT_APP_APP_STORE_URL}>Download App</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>

            {/* End Slider Area   */}

            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

        </div>
    )
}

export default PortfolioLanding;
