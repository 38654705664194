import React, { Component } from "react";
import {
    Container, Row, Col, Card, CardBody, Label, FormGroup, Button, Alert,
    InputGroup, InputGroupAddon, Spinner,
    UncontrolledAlert
} from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { User, Mail } from 'react-feather';
import { isMobile } from 'react-device-detect';
const axios = require('axios').default;

class ContactOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            message: ''
        };
    }

    handleSendMessage = (event, { username, email, textarea }) => {
        const body = { 'first_n_last': username, 'email': email, 'message_body': textarea };
        const headers = { 'Content-Type': 'application/json' };
        const url = process.env.REACT_APP_BASE_URL + '/landing/send_message/';

        this.setState({username: username, email: email, message: textarea});

        const instance = axios.create({
            baseURL: url,
            timeout: 10000,
            headers: headers
        });
        instance.post("/", body).then((response) => {
            console.log(response)
            return response.data;
        })
            .then(json => {
                alert('You message has been sent!');
                this.setState({username: '', email: '', message: ''});
            })
            .catch(error => {
                alert(error.toString());
            });
    }

    render() {
        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-12 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <div className="section-title text-center mb--30 mb_sm--0">
                                    <br />
                                    <p className="section-title">Contact Us</p>
                                    <p className="section-description">Please reach out to us with any comments, questions or concerns!</p>
                                </div>
                            </div>
                            <div className="form-wrapper">
                                <AvForm onValidSubmit={this.handleSendMessage} className="authentication-form">
                                    {isMobile ? <div>
                                        <Row>
                                            <Col>
                                                <AvGroup className="">
                                                    <Label for="username" className="contact-style">First & Last Name :</Label>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <span className="input-group-text">
                                                                <User className="icon-dual" />
                                                            </span>
                                                        </InputGroupAddon>
                                                        <AvInput type="text" name="username" id="username" placeholder="Write your first and last name..." value={this.state.username} required />
                                                    </InputGroup>

                                                    <AvFeedback>This field is invalid</AvFeedback>
                                                </AvGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <AvGroup className="mb-3">
                                                    <Label for="email" className="contact-style">Email Address: </Label>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <span className="input-group-text">
                                                                <Mail className="icon-dual" />
                                                            </span>
                                                        </InputGroupAddon>
                                                        <AvInput type="email" name="email" id="email" placeholder="Write your email address..." value={this.state.email} required />
                                                    </InputGroup>
                                                    <AvFeedback>This field is invalid</AvFeedback>
                                                </AvGroup>
                                            </Col>
                                        </Row>
                                    </div> : <Row>
                                        <Col>
                                            <AvGroup className="">
                                                <Label for="username" className="contact-style">First & Last Name :</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <span className="input-group-text">
                                                            <User className="icon-dual" />
                                                        </span>
                                                    </InputGroupAddon>
                                                    <AvInput type="text" name="username" id="username" placeholder="Write your first and last name..." value={this.state.username} required />
                                                </InputGroup>

                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                        </Col>
                                        <Col>
                                            <AvGroup className="mb-3">
                                                <Label for="email" className="contact-style">Email Address: </Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <span className="input-group-text">
                                                            <Mail className="icon-dual" />
                                                        </span>
                                                    </InputGroupAddon>
                                                    <AvInput type="email" name="email" id="email" placeholder="Write your email address..." value={this.state.email} required />
                                                </InputGroup>
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                        </Col>
                                    </Row>}

                                    <Row>
                                        <Col>
                                            <AvGroup className="">
                                                <Label for="username" className="contact-style">Your Message :</Label>
                                                <InputGroup>

                                                    <AvInput type="textarea" name="textarea" id="textarea" placeholder="Write your message here..." value={this.state.message}/>
                                                </InputGroup>

                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup className="form-group mb-0 text-center">
                                        <div className="row align-items-center justify-content-center">
                                            <Button className="btn-default-green" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Send Message</Button>
                                        </div>
                                    </FormGroup>
                                </AvForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactOne;