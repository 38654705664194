import React from 'react';
import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn, FaSpotify,
FaSoundcloud, FaDiscord, FaYoutube } from "react-icons/fa";
import {isMobile} from 'react-device-detect';

const SocialShare = [
    { Social: <FaInstagram />, link: 'https://www.instagram.com/undergroundsound/' },
    { Social: <FaSpotify />, link: 'https://open.spotify.com/user/yfj7fejxnwquw720lcqqj61y2?si=P8Hg8SQcQe2Xeu2ZSodChw' },
    { Social: <FaSoundcloud />, link: 'https://soundcloud.com/ugsm' },
    { Social: <FaDiscord />, link: 'https://discord.gg/tRAb88m' },
    { Social: <FaYoutube />, link: 'https://www.youtube.com/channel/UCLMONznzSaFFrPxkTXLpffQ?view_as=subscriber' },
    { Social: <FaTwitter />, link: 'https://twitter.com/therealugs' },
]

const FooterTwo = () => {
    return (
        <div className="footer-style-2 ptb--30 bg_color--6">
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div className="inner">
                            <div className="logo text-center text-sm-left mb_sm--20">
                                <a href="/">
                                    {
                                    isMobile ? <img className="logo-text-mobile" src="/assets/images/ugs-text-logo.png" alt="Logo images" /> :
                                    <img className="logo-text" src="/assets/images/ugs-text-logo.png" alt="Logo images" />
                                    }
                                    </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-4 col-12 align-items-center justify-content-center">
                        <nav className="mainmenunav d-lg-block text-center">
                            <ul className="mainmenu">
                                <li><a href="#home">Home</a></li>
                                <li><a href="#mission">Our Mission</a></li>
                                <li><a href="#howitworks">How It Works</a></li>
                                <li><a href="#contact">Contact Us</a></li>
                                <li><a href="https://www.undergroundsound.blog">Blog</a></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                        <div className="inner text-center">
                            <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                {SocialShare.map((val, i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterTwo;