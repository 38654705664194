import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi";

const WorksList = [
    {
        icon: <img class="service-logo" src={`/assets/images/icon_upload.png`} alt="How Our App Works" />,
        //icon: <FiLayers />,
        title: 'Upload music.',
        description: 'Artists upload music to our platform seamlessly. Zero upload limitations.'
    },
    {
        icon: <img class="service-logo" src={`/assets/images/icon_download.png`} alt="How Our App Works" />,
        //icon: <FiCast />,
        title: 'Stream music.',
        description: 'All users can stream and discover unlimited music for free.'
    },
    {
        icon: <img class="service-logo" src={`/assets/images/icon_network.png`} alt="How Our App Works" />,
        //icon: <FiMonitor />,
        title: 'Network.',
        description: 'Users can connect with each other and build their own community on UGS.'
    },
    {
        icon: <img class="service-logo" src={`/assets/images/icon_activity.png`} alt="How Our App Works" />,
        //icon: <FiUsers />,
        title: 'Support.',
        description: 'Users can show real support to each other via UGS Gems & reviews.'
    }
]


class WorksThree extends Component {
    render() {
        const { column } = this.props;
        const ServiceContent = WorksList.slice(0, this.props.item);

        return (
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map((val, i) => (
                        <div className={`${column}`} key={i}>
                            <div className="service service__style--2 text-left bg-gray">
                                {(i % 2 == 0) ? <div className="icon_service">
                                    {val.icon}
                                    <div className="text-left">
                                        <p className="item-title-large">{val.title}</p>
                                        <p className="item-description-large">{val.description}</p>
                                    </div>
                                </div>
                                    : <div className="icon_service">
                                        <div className="text-left">
                                            <p className="item-title-large">{val.title}</p>
                                            <p className="item-description-large">{val.description}</p>
                                        </div>
                                        {val.icon}
                                    </div>
                                }

                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default WorksThree;
